import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Lottie from 'react-lottie'

import * as api from "../../../api/Local"

import useQuery from "../../_shared/hooks/useQuery"

import * as loader from "./loading.json"

// ----------------------------------------------------------------------------

function PaypalPayment(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------


    const query = useQuery()

    const orderAmount = query.get("amount")
    const approveStatus = query.get("status")
    const approveToken = query.get("token")
    const intentQuery = query.get("intent")

    const shouldCapture = intentQuery === "authorize" ? false : true

    // -------------------------------------
    // Effects
    // -------------------------------------


    useEffect(() => {
        if (approveStatus === "approved" && approveToken) {
            capturePaypalPayment(approveToken)
        }
        else {
            createPaypalPayment()
        }

        if (window?.ReactNativeWebView?.postMessage) {
            console.log("ReactNativeWebview.postMessage is available");
            window?.ReactNativeWebview?.postMessage?.()
        } else {
            console.log("ReactNativeWebview.postMessage is NOT available");
        }

        //eslint-disable-next-line 
    }, [])


    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function capturePaypalPayment(orderId) {

        try {
            if (shouldCapture) {
                const captureResponse = await api.capturePayPalOrder(orderId)

                window?.ReactNativeWebView?.postMessage?.(JSON.stringify(captureResponse))
            }
            else {
                const order = await await api.getPayPalOrder(orderId)

                const bridgePayload = {
                    ...order,
                }

                window?.ReactNativeWebView?.postMessage?.(JSON.stringify(bridgePayload))
            }

        } catch (error) {

        }


    }

    async function createPaypalPayment() {
        if (orderAmount > 0) {

            const intent = shouldCapture ? "CAPTURE" : "AUTHORIZE"
            const { url } = await api.createPayPalOrder(orderAmount, intent)

            window.location = url
        }
    }


    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const lottietOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className={props.className}>
            <Lottie options={lottietOptions}
                height={100}
                width={100}
            />

            {!shouldCapture && (<p>Stai per essere dirottato al metodo di pagamento</p>)}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

PaypalPayment.propTypes = {
    className: PropTypes.string.isRequired,
}

PaypalPayment.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledPaypalPayment = styled(PaypalPayment)`
  & {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    > div {
        width: 100vw;
        max-width: 500px;
    }

    > p {
        text-align: center;
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledPaypalPayment